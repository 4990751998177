// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Game = require("./game");
var Player = require("./player");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function privacy_of_string(param) {
  switch (param) {
    case "PRIVATE" :
        return /* PRIVATE */1;
    case "PUBLIC" :
        return /* PUBLIC */0;
    default:
      return ;
  }
}

function string_of_privacy(param) {
  if (param) {
    return "PRIVATE";
  } else {
    return "PUBLIC";
  }
}

function state_from_json(json) {
  var state_str = Json_decode.field("state", Json_decode.string, json);
  if (state_str === "IN_GAME") {
    return /* IN_GAME */1;
  } else {
    return /* IN_LOBBY */0;
  }
}

function privacy_from_json(json) {
  var privacy_str = Json_decode.field("privacy", Json_decode.string, json);
  var match = privacy_of_string(privacy_str);
  if (match !== undefined) {
    return match;
  } else {
    return Pervasives.failwith("Invalid privacy");
  }
}

function make(id, leader, players, create_time, board_size, privacy, state, game) {
  return /* record */[
          /* id */id,
          /* leader */leader,
          /* players */players,
          /* create_time */create_time,
          /* board_size */board_size,
          /* privacy */privacy,
          /* state */state,
          /* game */game
        ];
}

function lobby_of_json(json) {
  var id = Json_decode.field("lobby_id", Json_decode.string, json);
  var create_time = Json_decode.field("create_time", Json_decode.date, json);
  var leader = Json_decode.field("leader", Player.player_of_json, json);
  var players = Json_decode.field("players", (function (param) {
          return Json_decode.array(Player.player_of_json, param);
        }), json);
  var board_size = Json_decode.field("board_size", Json_decode.$$int, json);
  var game = Json_decode.optional((function (param) {
          return Json_decode.field("game", Game.game_of_json, param);
        }), json);
  var privacy = privacy_from_json(json);
  var state = state_from_json(json);
  return make(id, leader, players, create_time, board_size, privacy, state, game);
}

function get_id(param) {
  return param[/* id */0];
}

function get_board_size(param) {
  return param[/* board_size */4];
}

function get_privacy(param) {
  return param[/* privacy */5];
}

function is_leader(player, param) {
  return Player.equal(player, param[/* leader */1]);
}

function get_players(param) {
  return param[/* players */2];
}

function get_state(param) {
  return param[/* state */6];
}

function get_game(param) {
  return param[/* game */7];
}

exports.privacy_of_string = privacy_of_string;
exports.string_of_privacy = string_of_privacy;
exports.state_from_json = state_from_json;
exports.privacy_from_json = privacy_from_json;
exports.make = make;
exports.lobby_of_json = lobby_of_json;
exports.get_id = get_id;
exports.get_board_size = get_board_size;
exports.get_privacy = get_privacy;
exports.is_leader = is_leader;
exports.get_players = get_players;
exports.get_state = get_state;
exports.get_game = get_game;
/* No side effect */
