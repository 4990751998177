// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Game = require("../api/game");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Board = require("./board/Board");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Player = require("../api/player");
var Js_option = require("bs-platform/lib/js/js_option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

((require('./game.css')));

function str(prim) {
  return prim;
}

function istr(i) {
  return String(i);
}

function doMeasurementUpdate(self) {
  var match = self[/* state */1][/* divRef */1][0];
  if (match !== undefined) {
    var obj = Caml_option.valFromOption(match).getBoundingClientRect();
    var width = obj.width | 0;
    var height = obj.height | 0;
    var dims = /* tuple */[
      width,
      height
    ];
    return Curry._1(self[/* send */3], /* SetDims */[dims]);
  } else {
    return /* () */0;
  }
}

function updateMeasurements(self) {
  if (Js_option.isSome(self[/* state */1][/* dims */0])) {
    return /* () */0;
  } else {
    return doMeasurementUpdate(self);
  }
}

function setDivRef(theRef, self) {
  var theRef$1 = (theRef == null) ? undefined : Caml_option.some(theRef);
  self[/* state */1][/* divRef */1][0] = theRef$1;
  return updateMeasurements(self);
}

var component = ReasonReact.reducerComponent("GameScreen");

function make(players, game, rotateHex, hideGameBoard, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */(function (self) {
              var init = self[/* state */1];
              return /* record */[
                      /* dims */init[/* dims */0],
                      /* divRef */init[/* divRef */1],
                      /* timeRemaining */Game.get_time_remaining(game)
                    ];
            }),
          /* didMount */(function (self) {
              var intervalId = setInterval((function (param) {
                      return Curry._1(self[/* send */3], /* Tick */0);
                    }), 1000);
              var f = function (param) {
                return doMeasurementUpdate(self);
              };
              addEventListener("resize", f);
              return Curry._1(self[/* onUnmount */4], (function (param) {
                            clearInterval(intervalId);
                            removeEventListener("resize", f);
                            return /* () */0;
                          }));
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var maze = Game.get_maze(game);
              var solution = Game.get_solution(game);
              var state = Game.get_state(game);
              var match = self[/* state */1][/* dims */0];
              var tmp;
              if (match !== undefined) {
                var match$1 = match;
                tmp = ReasonReact.element(undefined, undefined, Board.make(match$1[0], match$1[1], maze, solution, rotateHex, /* array */[]));
              } else {
                tmp = null;
              }
              var tmp$1;
              switch (state) {
                case /* WAITING */0 :
                    tmp$1 = "Waiting...";
                    break;
                case /* PLAYING */1 :
                    tmp$1 = "In game...";
                    break;
                case /* SUCCESS */2 :
                    tmp$1 = "You win!";
                    break;
                case /* FAILURE */3 :
                    tmp$1 = "You lose...";
                    break;
                
              }
              return React.createElement("div", {
                          className: "game"
                        }, React.createElement("div", {
                              ref: Curry._1(self[/* handle */0], setDivRef),
                              className: "board-wrapper"
                            }, tmp, state >= 3 ? React.createElement("div", {
                                    className: "failure-cover"
                                  }, React.createElement("div", {
                                        className: "failure-text"
                                      }, "Sorry, you didn't solve the maze quick enough", React.createElement("br", undefined), "Better luck next time!")) : null), React.createElement("div", {
                              className: "game-stats-container"
                            }, React.createElement("div", {
                                  className: "game-stats"
                                }, React.createElement("div", {
                                      className: "game-state"
                                    }, tmp$1), React.createElement("div", {
                                      className: "number-of-players"
                                    }, "Number of players: ", String(players.length)), React.createElement("div", {
                                      className: "time-remaining"
                                    }, "Time remaining: ", String((
                                          state !== 1 ? (
                                              state !== 0 ? Game.get_time_remaining(game) : 0
                                            ) : self[/* state */1][/* timeRemaining */2]
                                        ) / 1000 | 0)), React.createElement("div", undefined, state >= 2 ? React.createElement("button", {
                                            onClick: hideGameBoard
                                          }, "Return to lobby") : null)), React.createElement("div", {
                                  className: "game-stats game-players-container"
                                }, React.createElement("div", undefined, "Players:"), $$Array.mapi((function (index, player) {
                                        var className = "game-player p" + (String(index) + "-coloured");
                                        return React.createElement("div", {
                                                    key: Player.get_id(player),
                                                    className: className
                                                  }, Player.get_displayable_name(player));
                                      }), players))));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* dims */undefined,
                      /* divRef : record */[/* contents */undefined],
                      /* timeRemaining */Game.get_time_remaining(game)
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[
                            /* dims */action[0],
                            /* divRef */state[/* divRef */1],
                            /* timeRemaining */state[/* timeRemaining */2]
                          ]]);
              } else {
                return /* Update */Block.__(0, [/* record */[
                            /* dims */state[/* dims */0],
                            /* divRef */state[/* divRef */1],
                            /* timeRemaining */state[/* timeRemaining */2] - 1000 | 0
                          ]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.istr = istr;
exports.doMeasurementUpdate = doMeasurementUpdate;
exports.updateMeasurements = updateMeasurements;
exports.setDivRef = setDivRef;
exports.component = component;
exports.make = make;
/*  Not a pure module */
