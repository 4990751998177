// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var ReactKonva = require("react-konva");
var Js_null_undefined = require("bs-platform/lib/js/js_null_undefined.js");

function make(width, height, onContentContextmenu, children) {
  return ReasonReact.wrapJsForReason(ReactKonva.Stage, {
              width: width,
              height: height,
              onContentContextmenu: Js_null_undefined.fromOption(onContentContextmenu)
            }, children);
}

var Stage = {
  make: make
};

function make$1(children) {
  return ReasonReact.wrapJsForReason(ReactKonva.Layer, { }, children);
}

var Layer = {
  make: make$1
};

function make$2(x, y, onClick, children) {
  return ReasonReact.wrapJsForReason(ReactKonva.Group, {
              x: x,
              y: y,
              onClick: Js_null_undefined.fromOption(onClick)
            }, children);
}

var Group = {
  make: make$2
};

function make$3(x, y, radius, fill, stroke, children) {
  return ReasonReact.wrapJsForReason(ReactKonva.RegularPolygon, {
              sides: 6,
              x: x,
              y: y,
              radius: radius,
              fill: fill,
              stroke: stroke
            }, children);
}

var Hexagon = {
  make: make$3
};

function make$4(stroke, strokeWidth, points, children) {
  return ReasonReact.wrapJsForReason(ReactKonva.Line, {
              points: points,
              stroke: stroke,
              strokeWidth: strokeWidth
            }, children);
}

var Line = {
  make: make$4
};

function make$5(x, y, radius, fill, stroke, children) {
  return ReasonReact.wrapJsForReason(ReactKonva.Circle, {
              sides: 6,
              x: x,
              y: y,
              radius: radius,
              fill: fill,
              stroke: stroke
            }, children);
}

var Circle = {
  make: make$5
};

exports.Stage = Stage;
exports.Layer = Layer;
exports.Group = Group;
exports.Hexagon = Hexagon;
exports.Line = Line;
exports.Circle = Circle;
/* ReasonReact Not a pure module */
