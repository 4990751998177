// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var JoinSpecificLobby = require("./JoinSpecificLobby");

((require('./menu.css')));

var component = ReasonReact.reducerComponent("Menu");

function make(onCreateLobby, onJoinLobby, onJoinRandomLobby, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* showLobbyTypeSelect */0];
              return React.createElement("div", {
                          className: "menu"
                        }, match ? React.createElement("div", undefined, React.createElement("button", {
                                    className: "menu-button",
                                    onClick: (function (param) {
                                        return Curry._1(self[/* send */3], /* ToggleLobbySelect */0);
                                      })
                                  }, "Back"), React.createElement("button", {
                                    className: "menu-button",
                                    onClick: (function (param) {
                                        return Curry._1(onCreateLobby, /* tuple */[
                                                    4,
                                                    /* PUBLIC */0
                                                  ]);
                                      })
                                  }, "Create Public Lobby"), React.createElement("button", {
                                    className: "menu-button",
                                    onClick: (function (param) {
                                        return Curry._1(onCreateLobby, /* tuple */[
                                                    4,
                                                    /* PRIVATE */1
                                                  ]);
                                      })
                                  }, "Create Private Lobby")) : React.createElement("div", undefined, React.createElement("button", {
                                    className: "menu-button",
                                    onClick: (function (param) {
                                        return Curry._1(self[/* send */3], /* ToggleLobbySelect */0);
                                      })
                                  }, "Create Lobby"), ReasonReact.element(undefined, undefined, JoinSpecificLobby.make(onJoinLobby, /* array */[])), React.createElement("button", {
                                    className: "menu-button",
                                    onClick: (function (param) {
                                        return Curry._1(onJoinRandomLobby, /* () */0);
                                      })
                                  }, "Join Random Lobby")));
            }),
          /* initialState */(function (param) {
              return /* record */[/* showLobbyTypeSelect */false];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              return /* Update */Block.__(0, [/* record */[/* showLobbyTypeSelect */!state[/* showLobbyTypeSelect */0]]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/*  Not a pure module */
