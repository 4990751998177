// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Hex = require("./hex");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function make(number_of_players, maze_size, start_point, end_point, layout) {
  return /* record */[
          /* number_of_players */number_of_players,
          /* maze_size */maze_size,
          /* layout */layout,
          /* start_point */start_point,
          /* end_point */end_point
        ];
}

function layout_of_json(json) {
  return Json_decode.field("layout", (function (param) {
                return Json_decode.array((function (param) {
                              return Json_decode.array(Hex.hex_of_json, param);
                            }), param);
              }), json);
}

function maze_of_json(json) {
  var number_of_players = Json_decode.field("number_of_players", Json_decode.$$int, json);
  var maze_size = Json_decode.field("maze_size", Json_decode.$$int, json);
  var start_point = Json_decode.field("start_point", (function (param) {
          return Json_decode.pair(Json_decode.$$int, Json_decode.$$int, param);
        }), json);
  var end_point = Json_decode.field("end_point", (function (param) {
          return Json_decode.pair(Json_decode.$$int, Json_decode.$$int, param);
        }), json);
  var layout = layout_of_json(json);
  return make(number_of_players, maze_size, start_point, end_point, layout);
}

function get_layout(param) {
  return param[/* layout */2];
}

function get_start_point(param) {
  return param[/* start_point */3];
}

function get_end_point(param) {
  return param[/* end_point */4];
}

function get_maze_size(param) {
  return param[/* maze_size */1];
}

exports.make = make;
exports.layout_of_json = layout_of_json;
exports.maze_of_json = maze_of_json;
exports.get_layout = get_layout;
exports.get_start_point = get_start_point;
exports.get_end_point = get_end_point;
exports.get_maze_size = get_maze_size;
/* No side effect */
