// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("bs-platform/lib/js/string.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function status_of_string(param) {
  switch (param) {
    case "IN_GAME" :
        return /* IN_GAME */2;
    case "IN_LOBBY" :
        return /* IN_LOBBY */1;
    case "IN_MENUS" :
        return /* IN_MENUS */0;
    default:
      return Pervasives.failwith("Could not parse user location");
  }
}

function equal(a, b) {
  return a[/* id */0] === b[/* id */0];
}

function compare(a, b) {
  return $$String.compare(a[/* id */0], b[/* id */0]);
}

function get_id(param) {
  return param[/* id */0];
}

function get_nickname(param) {
  return param[/* nickname */1];
}

function get_displayable_name(param) {
  var nickname = param[/* nickname */1];
  if (nickname === "") {
    return param[/* id */0];
  } else {
    return nickname;
  }
}

function get_status(param) {
  return param[/* status */2];
}

function make(id, nickname, status) {
  return /* record */[
          /* id */id,
          /* nickname */nickname,
          /* status */status
        ];
}

function update_status(status, t) {
  return /* record */[
          /* id */t[/* id */0],
          /* nickname */t[/* nickname */1],
          /* status */status
        ];
}

function player_of_json(json) {
  var id = Json_decode.field("player_id", Json_decode.string, json);
  var nickname = Json_decode.field("nickname", Json_decode.string, json);
  var status = status_of_string(Json_decode.field("status", Json_decode.string, json));
  return /* record */[
          /* id */id,
          /* nickname */nickname,
          /* status */status
        ];
}

exports.status_of_string = status_of_string;
exports.equal = equal;
exports.compare = compare;
exports.get_id = get_id;
exports.get_nickname = get_nickname;
exports.get_displayable_name = get_displayable_name;
exports.get_status = get_status;
exports.make = make;
exports.update_status = update_status;
exports.player_of_json = player_of_json;
/* No side effect */
