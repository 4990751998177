// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var App = require("./App");
var ReactDOMRe = require("reason-react/lib/js/src/ReactDOMRe.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RegisterServiceWorker = require("./registerServiceWorker");

((require('./index.css')));

function register_service_worker(prim) {
  RegisterServiceWorker.default();
  return /* () */0;
}

ReactDOMRe.renderToElementWithId(ReasonReact.element(undefined, undefined, App.make(/* array */[])), "root");

RegisterServiceWorker.default();

exports.register_service_worker = register_service_worker;
/*  Not a pure module */
