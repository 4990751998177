// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

((require('./landing.css')));

function str(prim) {
  return prim;
}

var component = ReasonReact.reducerComponent("Landing");

function make(onConnect, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", {
                          className: "landing"
                        }, React.createElement("h1", {
                              className: "loading-intro"
                            }, "Welcome to Hexagons!"), React.createElement("form", {
                              className: "landing-form",
                              action: "#",
                              onSubmit: (function (param) {
                                  return Curry._1(onConnect, self[/* state */1][/* nickname */0]);
                                })
                            }, React.createElement("label", undefined, "Nickname", React.createElement("input", {
                                      className: "landing-nickname",
                                      placeholder: "Nickname",
                                      type: "text",
                                      value: self[/* state */1][/* nickname */0],
                                      onChange: (function (e) {
                                          return Curry._1(self[/* send */3], /* Update_nickname */[e.target.value]);
                                        })
                                    })), React.createElement("button", {
                                  type: "submit"
                                }, "Connect =>")));
            }),
          /* initialState */(function (param) {
              return /* record */[/* nickname */""];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, _state) {
              return /* Update */Block.__(0, [/* record */[/* nickname */action[0]]]);
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.component = component;
exports.make = make;
/*  Not a pure module */
