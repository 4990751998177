// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Player = require("../api/player");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var HexagonzPng = require("./hexagonz.png");

((require('./header.css')));

var hexagonz = HexagonzPng;

var component = ReasonReact.statelessComponent("Header");

function make(player, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              return React.createElement("div", {
                          className: "header"
                        }, React.createElement("img", {
                              className: "logo",
                              src: hexagonz
                            }), React.createElement("div", {
                              className: "player_name"
                            }, player !== undefined ? Player.get_displayable_name(player) : ""));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.hexagonz = hexagonz;
exports.component = component;
exports.make = make;
/*  Not a pure module */
