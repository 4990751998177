// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");

function string_of_side(param) {
  switch (param) {
    case /* TOP_RIGHT */0 :
        return "TOP_RIGHT";
    case /* MIDDLE_RIGHT */1 :
        return "MIDDLE_RIGHT";
    case /* BOTTOM_RIGHT */2 :
        return "BOTTOM_RIGHT";
    case /* BOTTOM_LEFT */3 :
        return "BOTTOM_LEFT";
    case /* MIDDLE_LEFT */4 :
        return "MIDDLE_LEFT";
    case /* TOP_LEFT */5 :
        return "TOP_LEFT";
    
  }
}

function side_of_string(param) {
  switch (param) {
    case "BOTTOM_LEFT" :
        return /* BOTTOM_LEFT */3;
    case "BOTTOM_RIGHT" :
        return /* BOTTOM_RIGHT */2;
    case "MIDDLE_LEFT" :
        return /* MIDDLE_LEFT */4;
    case "MIDDLE_RIGHT" :
        return /* MIDDLE_RIGHT */1;
    case "TOP_LEFT" :
        return /* TOP_LEFT */5;
    case "TOP_RIGHT" :
        return /* TOP_RIGHT */0;
    default:
      return Pervasives.failwith("invaid side");
  }
}

exports.string_of_side = string_of_side;
exports.side_of_string = side_of_string;
/* No side effect */
