// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Menu = require("./menu/Menu");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Header = require("./header/Header");
var Landing = require("./landing/Landing");
var Loading = require("./loading/Loading");
var Messaging = require("./api/messaging");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var LobbyScreen = require("./lobby/LobbyScreen");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

((require('./app.css')));

function dispatchMessage(message, self) {
  var tmp;
  if (typeof message === "number") {
    switch (message) {
      case /* LEFT_LOBBY */0 :
      case /* LOBBY_CLOSED */1 :
          tmp = /* Leave_lobby */2;
          break;
      case /* UNIMPLIMENTED */2 :
          tmp = /* Unimplimented */3;
          break;
      
    }
  } else {
    switch (message.tag | 0) {
      case /* ERROR */0 :
          tmp = /* Err */Block.__(3, [message[0]]);
          break;
      case /* ABOUT_ME */1 :
      case /* CONNECTED */7 :
          tmp = /* Update_player */Block.__(1, [message[0]]);
          break;
      default:
        tmp = /* Update_lobby */Block.__(2, [message[0]]);
    }
  }
  return Curry._1(self[/* send */3], tmp);
}

function setNicknameInStorage(nickname) {
  localStorage.setItem("nickname", nickname);
  return /* () */0;
}

function getNicknameFromStorage(param) {
  return Caml_option.null_to_opt(localStorage.getItem("nickname"));
}

var component = ReasonReact.reducerComponent("App");

function make(_children) {
  var connect = function (nickname, self) {
    var onConnect = function (param) {
      return Curry._1(self[/* send */3], /* WS_connected */0);
    };
    var onClose = function (param) {
      return Curry._1(self[/* send */3], /* WS_disconnected */1);
    };
    var onMessage = Curry._1(self[/* handle */0], dispatchMessage);
    var messaging = Messaging.make(nickname, onConnect, onClose, onMessage, /* () */0);
    return Curry._1(self[/* send */3], /* WS_connect */Block.__(0, [messaging]));
  };
  var connectAndSaveNickname = function (nickname) {
    setNicknameInStorage(nickname);
    return (function (param) {
        return connect(nickname, param);
      });
  };
  var reconnect = function (param, self) {
    var match = localStorage.getItem("nickname");
    if (match !== null) {
      return connect(match, self);
    } else {
      return connect("", self);
    }
  };
  var createLobby = function (options, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Make_Lobby */Block.__(0, [options]));
  };
  var joinLobby = function (lobbyId, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Join_Lobby */Block.__(1, [lobbyId]));
  };
  var joinRandomLobby = function (param, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Join_Random_Lobby */1);
  };
  var leaveLobby = function (param, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Leave_Lobby */2);
  };
  var changeBoardSize = function (board_size, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Edit_Lobby_Board_Size */Block.__(2, [board_size]));
  };
  var changePrivacy = function (privacy, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Edit_Lobby_Privacy */Block.__(3, [privacy]));
  };
  var startGame = function (param, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Start_Game */4);
  };
  var rotateHex = function (rotation, self) {
    return Messaging.send_message_option(self[/* state */1][/* messaging */1])(/* Rotate_Hex */Block.__(4, [rotation]));
  };
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */(function (self) {
              var match = localStorage.getItem("nickname");
              if (match !== null) {
                return connect(match, self);
              } else {
                return /* () */0;
              }
            }),
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* player */2];
              var tmp;
              if (match !== undefined) {
                var match$1 = self[/* state */1][/* lobby */3];
                tmp = match$1 !== undefined ? ReasonReact.element(undefined, undefined, LobbyScreen.make(match$1, Curry._1(self[/* handle */0], leaveLobby), Curry._1(self[/* handle */0], changeBoardSize), Curry._1(self[/* handle */0], changePrivacy), Curry._1(self[/* handle */0], startGame), Curry._1(self[/* handle */0], rotateHex), /* array */[])) : ReasonReact.element(undefined, undefined, Menu.make(Curry._1(self[/* handle */0], createLobby), Curry._1(self[/* handle */0], joinLobby), Curry._1(self[/* handle */0], joinRandomLobby), /* array */[]));
              } else {
                var match$2 = self[/* state */1][/* messaging */1];
                tmp = match$2 !== undefined ? ReasonReact.element(undefined, undefined, Loading.make(/* array */[])) : ReasonReact.element(undefined, undefined, Landing.make(Curry._1(self[/* handle */0], connectAndSaveNickname), /* array */[]));
              }
              var match$3 = self[/* state */1][/* err */4];
              var match$4 = self[/* state */1][/* messaging */1];
              var tmp$1;
              if (match$4 !== undefined) {
                var match$5 = Messaging.get_state(match$4);
                tmp$1 = match$5 >= 3 ? React.createElement("div", {
                        className: "error-message error-closed"
                      }, "Connection to the server has closed", React.createElement("button", {
                            type: "button",
                            onClick: Curry._1(self[/* handle */0], reconnect)
                          }, "Reconnect")) : null;
              } else {
                tmp$1 = null;
              }
              return React.createElement("div", {
                          className: "app"
                        }, ReasonReact.element(undefined, undefined, Header.make(self[/* state */1][/* player */2], /* array */[])), React.createElement("div", {
                              className: "body"
                            }, tmp, match$3 !== undefined ? React.createElement("div", {
                                    className: "error-message"
                                  }, match$3) : null, tmp$1));
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* connected */false,
                      /* messaging */undefined,
                      /* player */undefined,
                      /* lobby */undefined,
                      /* err */undefined
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (typeof action === "number") {
                switch (action) {
                  case /* WS_connected */0 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */true,
                                  /* messaging */state[/* messaging */1],
                                  /* player */state[/* player */2],
                                  /* lobby */state[/* lobby */3],
                                  /* err */state[/* err */4]
                                ]]);
                  case /* WS_disconnected */1 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */false,
                                  /* messaging */state[/* messaging */1],
                                  /* player */state[/* player */2],
                                  /* lobby */state[/* lobby */3],
                                  /* err */state[/* err */4]
                                ]]);
                  case /* Leave_lobby */2 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */state[/* connected */0],
                                  /* messaging */state[/* messaging */1],
                                  /* player */state[/* player */2],
                                  /* lobby */undefined,
                                  /* err */state[/* err */4]
                                ]]);
                  case /* Unimplimented */3 :
                      return /* NoUpdate */0;
                  
                }
              } else {
                switch (action.tag | 0) {
                  case /* WS_connect */0 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */state[/* connected */0],
                                  /* messaging */action[0],
                                  /* player */state[/* player */2],
                                  /* lobby */undefined,
                                  /* err */state[/* err */4]
                                ]]);
                  case /* Update_player */1 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */state[/* connected */0],
                                  /* messaging */state[/* messaging */1],
                                  /* player */action[0],
                                  /* lobby */state[/* lobby */3],
                                  /* err */state[/* err */4]
                                ]]);
                  case /* Update_lobby */2 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */state[/* connected */0],
                                  /* messaging */state[/* messaging */1],
                                  /* player */state[/* player */2],
                                  /* lobby */action[0],
                                  /* err */state[/* err */4]
                                ]]);
                  case /* Err */3 :
                      return /* Update */Block.__(0, [/* record */[
                                  /* connected */state[/* connected */0],
                                  /* messaging */state[/* messaging */1],
                                  /* player */state[/* player */2],
                                  /* lobby */state[/* lobby */3],
                                  /* err */action[0]
                                ]]);
                  
                }
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.dispatchMessage = dispatchMessage;
exports.setNicknameInStorage = setNicknameInStorage;
exports.getNicknameFromStorage = getNicknameFromStorage;
exports.component = component;
exports.make = make;
/*  Not a pure module */
