// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Maze = require("../../api/maze");
var $$Array = require("bs-platform/lib/js/array.js");
var Konva = require("../../konva/Konva");
var BoardRow = require("./BoardRow");
var BoardCalc = require("./boardCalc");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("Board");

function make(width, height, maze, solution, onClick, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var layout = Maze.get_layout(maze);
              var maze_size = Maze.get_maze_size(maze);
              var boardCalc = BoardCalc.calculate_from_dimensions(width, height, maze_size);
              var points_000 = Maze.get_start_point(maze);
              var points_001 = Maze.get_end_point(maze);
              var points = /* tuple */[
                points_000,
                points_001
              ];
              var solution$1 = solution !== undefined ? solution : /* [] */0;
              return ReasonReact.element(undefined, undefined, Konva.Stage.make(width, height, (function (e) {
                                return e.evt.preventDefault();
                              }), /* array */[ReasonReact.element(undefined, undefined, Konva.Layer.make(/* array */[$$Array.mapi((function (yIndex, row) {
                                                return ReasonReact.element(String(yIndex), undefined, BoardRow.make(boardCalc, row, yIndex, points, solution$1, onClick, /* array */[]));
                                              }), layout)]))]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
