// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Side = require("./side");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function make(player, entry_gate, exit_gate) {
  return /* record */[
          /* player */player,
          /* entry_gate */entry_gate,
          /* exit_gate */exit_gate
        ];
}

function hex_of_json(json) {
  var player = Json_decode.field("player", Json_decode.$$int, json);
  var entry_gate = Side.side_of_string(Json_decode.field("entry_gate", Json_decode.string, json));
  var exit_gate = Side.side_of_string(Json_decode.field("exit_gate", Json_decode.string, json));
  return /* record */[
          /* player */player,
          /* entry_gate */entry_gate,
          /* exit_gate */exit_gate
        ];
}

function get_player(param) {
  return param[/* player */0];
}

function get_gates(param) {
  return /* tuple */[
          param[/* entry_gate */1],
          param[/* exit_gate */2]
        ];
}

exports.make = make;
exports.hex_of_json = hex_of_json;
exports.get_player = get_player;
exports.get_gates = get_gates;
/* No side effect */
