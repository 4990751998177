// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Konva = require("../../konva/Konva");
var BoardHex = require("./BoardHex");
var BoardCalc = require("./boardCalc");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.statelessComponent("BoardRow");

function make(boardCalc, row, yIndex, points, solution, onClick, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var evenY = yIndex % 2 === 0;
              var xPos = evenY ? 0 : boardCalc[/* xPush */4] / 2;
              var yPos = BoardCalc.calculate_y_position(boardCalc, yIndex);
              return ReasonReact.element(undefined, undefined, Konva.Group.make(xPos, yPos, undefined, /* array */[$$Array.mapi((function (xIndex, hex) {
                                      return ReasonReact.element(String(xIndex), undefined, BoardHex.make(boardCalc, hex, yIndex, xIndex, points, solution, onClick, /* array */[]));
                                    }), row)]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
