// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function bind(f, param) {
  if (param !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(param));
  }
  
}

function map(f, param) {
  if (param !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(param)));
  }
  
}

function $great$great$eq(o, f) {
  return bind(f, o);
}

function $great$great$pipe(o, f) {
  return map(f, o);
}

exports.bind = bind;
exports.map = map;
exports.$great$great$eq = $great$great$eq;
exports.$great$great$pipe = $great$great$pipe;
/* No side effect */
