// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function solution_of_list(l) {
  return l;
}

function solution_to_list(t) {
  return t;
}

function solution_of_json(json) {
  return Json_decode.list((function (param) {
                return Json_decode.pair(Json_decode.$$int, Json_decode.$$int, param);
              }), json);
}

function tuple_in_solution(t, tuple) {
  return List.mem(tuple, t);
}

function is_in_solution(t, y, x) {
  return List.mem(/* tuple */[
              y,
              x
            ], t);
}

exports.solution_of_list = solution_of_list;
exports.solution_to_list = solution_to_list;
exports.solution_of_json = solution_of_json;
exports.tuple_in_solution = tuple_in_solution;
exports.is_in_solution = is_in_solution;
/* No side effect */
