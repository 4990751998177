// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Lobby = require("./lobby");
var $$Option = require("./option");
var Player = require("./player");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var WebSockets = require("bs-websockets/lib/js/src/webSockets.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");

var match = process.env.REACT_APP_WS_URL;

var url;

if (match !== undefined) {
  var url$1 = match;
  url = url$1 === "" ? "ws://192.168.1.70:8000/ws" : url$1;
} else {
  url = "ws://192.168.1.70:8000/ws";
}

function make_error(json) {
  var error = Json_decode.string(json);
  return /* ERROR */Block.__(0, [error]);
}

function string_of_outgoing_message(param) {
  if (typeof param === "number") {
    switch (param) {
      case /* About_Me */0 :
          return "About_Me";
      case /* Join_Random_Lobby */1 :
          return "Join_Random_Lobby";
      case /* Leave_Lobby */2 :
          return "Leave_Lobby";
      case /* Close_Lobby */3 :
          return "Close_Lobby";
      case /* Start_Game */4 :
          return "Start_Game";
      
    }
  } else {
    switch (param.tag | 0) {
      case /* Make_Lobby */0 :
          return "Make_Lobby";
      case /* Join_Lobby */1 :
          return "Join_Lobby";
      case /* Edit_Lobby_Board_Size */2 :
          return "Edit_Lobby_Board_Size";
      case /* Edit_Lobby_Privacy */3 :
          return "Edit_Lobby_Privacy";
      case /* Rotate_Hex */4 :
          return "Rotate_Hex";
      
    }
  }
}

function make_incoming_message(m_type, data) {
  switch (m_type) {
    case "ABOUT_ME" :
        return /* ABOUT_ME */Block.__(1, [Player.player_of_json(data)]);
    case "CONNECTED" :
        return /* CONNECTED */Block.__(7, [Player.player_of_json(data)]);
    case "ERROR" :
        return make_error(data);
    case "GAME_FINISHED" :
        return /* GAME_FINISHED */Block.__(5, [Json_decode.field("lobby", Lobby.lobby_of_json, data)]);
    case "GAME_STARTED" :
        return /* GAME_STARTED */Block.__(4, [Lobby.lobby_of_json(data)]);
    case "GAME_UPDATE" :
        return /* GAME_UPDATE */Block.__(6, [Lobby.lobby_of_json(data)]);
    case "JOINED_LOBBY" :
        return /* JOINED_LOBBY */Block.__(2, [Json_decode.field("lobby", Lobby.lobby_of_json, data)]);
    case "LEFT_LOBBY" :
        return /* LEFT_LOBBY */0;
    case "LOBBY_CLOSED" :
        return /* LOBBY_CLOSED */1;
    case "LOBBY_UPDATE" :
        return /* LOBBY_UPDATE */Block.__(3, [Lobby.lobby_of_json(data)]);
    case "UNIMPLIMENTED" :
        return /* UNIMPLIMENTED */2;
    default:
      return Pervasives.failwith("invalid type");
  }
}

function generateMessage(onMessage, $$event) {
  var json = Json.parseOrRaise($$event.data);
  var param = $$Option.$great$great$eq(Js_json.decodeObject(json), (function (dict) {
          return $$Option.$great$great$eq(Js_dict.get(dict, "payload"), (function (payload) {
                        return $$Option.$great$great$eq(Js_json.decodeObject(payload), (function (dict) {
                                      return $$Option.$great$great$eq(Js_dict.get(dict, "type"), (function (m_type) {
                                                    var m_type$1 = Json_decode.string(m_type);
                                                    return $$Option.$great$great$pipe($$Option.$great$great$pipe(Js_dict.get(dict, "data"), (function (param) {
                                                                      return make_incoming_message(m_type$1, param);
                                                                    })), onMessage);
                                                  }));
                                    }));
                      }));
        }));
  if (param !== undefined) {
    return /* () */0;
  } else {
    return Pervasives.failwith("bad json");
  }
}

function make_outgoing_json(m_type, args) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "type",
                m_type
              ],
              /* :: */[
                /* tuple */[
                  "args",
                  args
                ],
                /* [] */0
              ]
            ]);
}

var empty_args = Json_encode.object_(/* [] */0);

function send_message(t, message) {
  var message_string = string_of_outgoing_message(message);
  var args;
  if (typeof message === "number") {
    switch (message) {
      case /* About_Me */0 :
      case /* Close_Lobby */3 :
          args = Pervasives.failwith("UNIMPLIMENTED");
          break;
      default:
        args = empty_args;
    }
  } else {
    switch (message.tag | 0) {
      case /* Make_Lobby */0 :
          var match = message[0];
          args = Json_encode.object_(/* :: */[
                /* tuple */[
                  "board_size",
                  match[0]
                ],
                /* :: */[
                  /* tuple */[
                    "privacy",
                    Lobby.string_of_privacy(match[1])
                  ],
                  /* [] */0
                ]
              ]);
          break;
      case /* Join_Lobby */1 :
          args = Json_encode.object_(/* :: */[
                /* tuple */[
                  "id",
                  message[0]
                ],
                /* [] */0
              ]);
          break;
      case /* Edit_Lobby_Board_Size */2 :
          args = Json_encode.object_(/* :: */[
                /* tuple */[
                  "board_size",
                  message[0]
                ],
                /* [] */0
              ]);
          break;
      case /* Edit_Lobby_Privacy */3 :
          args = Json_encode.object_(/* :: */[
                /* tuple */[
                  "privacy",
                  Lobby.string_of_privacy(message[0])
                ],
                /* [] */0
              ]);
          break;
      case /* Rotate_Hex */4 :
          var match$1 = message[0];
          var match$2 = match$1[0];
          args = Json_encode.object_(/* :: */[
                /* tuple */[
                  "col",
                  match$2[0]
                ],
                /* :: */[
                  /* tuple */[
                    "row",
                    match$2[1]
                  ],
                  /* :: */[
                    /* tuple */[
                      "amount",
                      match$1[1]
                    ],
                    /* [] */0
                  ]
                ]
              ]);
          break;
      
    }
  }
  var str = Json.stringify(make_outgoing_json(message_string, args));
  t[/* ws */0].send(str);
  return /* () */0;
}

function send_message_option(param) {
  if (param !== undefined) {
    var partial_arg = param;
    return (function (param) {
        return send_message(partial_arg, param);
      });
  } else {
    return (function (prim) {
        return /* () */0;
      });
  }
}

function make($staropt$star, onConnect, onClose, onMessage, param) {
  var nickname = $staropt$star !== undefined ? $staropt$star : "";
  var url$2 = url + ("?nickname=" + encodeURIComponent(nickname));
  var ws = WebSockets.$$WebSocket.on(/* Message */Block.__(2, [(function (param) {
              return generateMessage(onMessage, param);
            })]), WebSockets.$$WebSocket.on(/* Close */Block.__(0, [onClose]), WebSockets.$$WebSocket.on(/* Open */Block.__(3, [onConnect]), WebSockets.$$WebSocket.setBinaryType(/* ArrayBuffer */1, Curry._1(WebSockets.$$WebSocket.make, url$2)))));
  return /* record */[
          /* ws */ws,
          /* nickname */nickname
        ];
}

function reconnect(param) {
  var partial_arg = param[/* nickname */1];
  return (function (param, param$1, param$2, param$3) {
      return make(partial_arg, param, param$1, param$2, param$3);
    });
}

function get_state(param) {
  return WebSockets.$$WebSocket.readyState(param[/* ws */0]);
}

exports.url = url;
exports.make_error = make_error;
exports.string_of_outgoing_message = string_of_outgoing_message;
exports.make_incoming_message = make_incoming_message;
exports.generateMessage = generateMessage;
exports.make_outgoing_json = make_outgoing_json;
exports.empty_args = empty_args;
exports.send_message = send_message;
exports.send_message_option = send_message_option;
exports.make = make;
exports.reconnect = reconnect;
exports.get_state = get_state;
/* match Not a pure module */
