// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Hex = require("../../api/hex");
var List = require("bs-platform/lib/js/list.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Konva = require("../../konva/Konva");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var BoardCalc = require("./boardCalc");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

var playerColours = /* array */[
  "red",
  "purple",
  "blue",
  "yellow"
];

var component = ReasonReact.statelessComponent("BoardHex");

function make(boardCalc, hex, yIndex, xIndex, points, solution, onClick, _children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (_self) {
              var playerNum = Hex.get_player(hex);
              var colour = Caml_array.caml_array_get(playerColours, playerNum);
              var positionTuple = /* tuple */[
                yIndex,
                xIndex
              ];
              var isStart = Caml_obj.caml_equal(positionTuple, points[0]);
              var isEnd = Caml_obj.caml_equal(positionTuple, points[1]);
              var xPos = BoardCalc.calculate_x_position(boardCalc, xIndex);
              var points$1 = BoardCalc.get_positions(boardCalc, Hex.get_gates(hex));
              var isInSolution = List.mem(positionTuple, solution);
              var tmp;
              if (isStart) {
                var match = BoardCalc.get_position_from_side(boardCalc, /* MIDDLE_LEFT */4);
                var exit = 0;
                if (match) {
                  var match$1 = match[1];
                  if (match$1 && !match$1[1]) {
                    tmp = ReasonReact.element(undefined, undefined, Konva.Circle.make(match[0], match$1[0], boardCalc[/* radius */0] / 5, "green", "black", /* array */[]));
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
                if (exit === 1) {
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "BoardHex.re",
                          68,
                          15
                        ]
                      ];
                }
                
              } else {
                tmp = null;
              }
              var tmp$1;
              if (isEnd) {
                var match$2 = BoardCalc.get_position_from_side(boardCalc, /* MIDDLE_RIGHT */1);
                var exit$1 = 0;
                if (match$2) {
                  var match$3 = match$2[1];
                  if (match$3 && !match$3[1]) {
                    tmp$1 = ReasonReact.element(undefined, undefined, Konva.Circle.make(match$2[0], match$3[0], boardCalc[/* radius */0] / 5, "green", "black", /* array */[]));
                  } else {
                    exit$1 = 1;
                  }
                } else {
                  exit$1 = 1;
                }
                if (exit$1 === 1) {
                  throw [
                        Caml_builtin_exceptions.assert_failure,
                        /* tuple */[
                          "BoardHex.re",
                          89,
                          15
                        ]
                      ];
                }
                
              } else {
                tmp$1 = null;
              }
              return ReasonReact.element(undefined, undefined, Konva.Group.make(xPos, 0, (function (e) {
                                var button = e.evt.button;
                                var match = button === 2;
                                var amount = match ? -1 : 1;
                                return Curry._1(onClick, /* tuple */[
                                            positionTuple,
                                            amount
                                          ]);
                              }), /* array */[
                              ReasonReact.element(undefined, undefined, Konva.Hexagon.make(0, 0, boardCalc[/* radius */0], colour, "black", /* array */[])),
                              ReasonReact.element(undefined, undefined, Konva.Line.make(isInSolution ? "green" : "black", boardCalc[/* radius */0] / 10, points$1, /* array */[])),
                              tmp,
                              tmp$1
                            ]));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.playerColours = playerColours;
exports.component = component;
exports.make = make;
/* component Not a pure module */
