// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");

var component = ReasonReact.reducerComponent("JoinSpecificLobby");

function make(onJoinLobby, _children) {
  var updateLobbyId = function ($$event, self) {
    return Curry._1(self[/* send */3], /* UpdateLobbyId */[$$event.target.value]);
  };
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var match = self[/* state */1][/* showLobbySelect */0];
              return React.createElement("div", undefined, React.createElement("button", {
                              className: "menu-button",
                              onClick: (function (param) {
                                  return Curry._1(self[/* send */3], /* ToggleLobbySelect */0);
                                })
                            }, "Join Specific Lobby"), match ? React.createElement("div", {
                                className: "menu-lobby-select"
                              }, React.createElement("input", {
                                    placeholder: "Lobby ID",
                                    type: "text",
                                    value: self[/* state */1][/* lobbyId */1],
                                    onChange: Curry._1(self[/* handle */0], updateLobbyId)
                                  }), React.createElement("button", {
                                    onClick: (function (param) {
                                        return Curry._1(onJoinLobby, self[/* state */1][/* lobbyId */1]);
                                      })
                                  }, "Join")) : null);
            }),
          /* initialState */(function (param) {
              return /* record */[
                      /* showLobbySelect */false,
                      /* lobbyId */""
                    ];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[
                            /* showLobbySelect */state[/* showLobbySelect */0],
                            /* lobbyId */action[0]
                          ]]);
              } else {
                return /* Update */Block.__(0, [/* record */[
                            /* showLobbySelect */!state[/* showLobbySelect */0],
                            /* lobbyId */state[/* lobbyId */1]
                          ]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.component = component;
exports.make = make;
/* component Not a pure module */
