// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");

var playerColours = /* array */[
  "red",
  "purple",
  "blue",
  "yellow"
];

function calculate_from_radius(radius) {
  var diameter = radius * 2;
  var xNudgeAmount = radius / 4;
  var xPush = diameter - xNudgeAmount;
  var yNudgeAmount = radius / 2;
  var yPush = diameter - yNudgeAmount;
  return /* record */[
          /* radius */radius,
          /* padding */radius,
          /* diameter */diameter,
          /* xNudgeAmount */xNudgeAmount,
          /* xPush */xPush,
          /* yNudgeAmount */yNudgeAmount,
          /* yPush */yPush
        ];
}

function calculate_from_dimensions(width, height, board_size) {
  var width$1 = width;
  var height$1 = height;
  var board_size$1 = board_size;
  var hex_number = board_size$1 + 1;
  var match = width$1 < height$1;
  if (match) {
    var yPush = height$1 / hex_number;
    var radius = 2 * yPush / 3;
    var diameter = radius * 2;
    var yNudgeAmount = radius / 2;
    var xNudgeAmount = radius / 4;
    var xPush = diameter - xNudgeAmount;
    return /* record */[
            /* radius */radius,
            /* padding */radius,
            /* diameter */diameter,
            /* xNudgeAmount */xNudgeAmount,
            /* xPush */xPush,
            /* yNudgeAmount */yNudgeAmount,
            /* yPush */yPush
          ];
  } else {
    var xPush$1 = width$1 / hex_number;
    var radius$1 = 4 * xPush$1 / 7;
    var diameter$1 = radius$1 * 2;
    var xNudgeAmount$1 = radius$1 / 4;
    var yNudgeAmount$1 = radius$1 / 2;
    var yPush$1 = diameter$1 - yNudgeAmount$1;
    return /* record */[
            /* radius */radius$1,
            /* padding */radius$1,
            /* diameter */diameter$1,
            /* xNudgeAmount */xNudgeAmount$1,
            /* xPush */xPush$1,
            /* yNudgeAmount */yNudgeAmount$1,
            /* yPush */yPush$1
          ];
  }
}

function calculate(width, height, board_size) {
  var comparitor = width < height ? width : height;
  var diameter = comparitor / board_size;
  return calculate_from_radius(diameter / 2);
}

function calculate_x_position(t, xIndex) {
  var x = xIndex;
  return t[/* padding */1] + t[/* xPush */4] * x;
}

function calculate_y_position(t, yIndex) {
  var y = yIndex;
  return t[/* padding */1] + t[/* yPush */6] * y;
}

function get_x_position_from_side(t, side) {
  switch (side) {
    case /* MIDDLE_RIGHT */1 :
        return t[/* xPush */4] / 2;
    case /* TOP_RIGHT */0 :
    case /* BOTTOM_RIGHT */2 :
        return t[/* xPush */4] / 4;
    case /* MIDDLE_LEFT */4 :
        return -(t[/* xPush */4] / 2);
    case /* BOTTOM_LEFT */3 :
    case /* TOP_LEFT */5 :
        return -(t[/* xPush */4] / 4);
    
  }
}

function get_y_position_from_side(t, side) {
  switch (side) {
    case /* BOTTOM_RIGHT */2 :
    case /* BOTTOM_LEFT */3 :
        return t[/* yPush */6] / 2;
    case /* MIDDLE_RIGHT */1 :
    case /* MIDDLE_LEFT */4 :
        return 0;
    case /* TOP_RIGHT */0 :
    case /* TOP_LEFT */5 :
        return -(t[/* yPush */6] / 2);
    
  }
}

function get_position_from_side(t, side) {
  return /* :: */[
          get_x_position_from_side(t, side),
          /* :: */[
            get_y_position_from_side(t, side),
            /* [] */0
          ]
        ];
}

var centre = /* :: */[
  0,
  /* :: */[
    0,
    /* [] */0
  ]
];

function get_positions(t, param) {
  return $$Array.of_list(List.flatten(/* :: */[
                  get_position_from_side(t, param[0]),
                  /* :: */[
                    centre,
                    /* :: */[
                      get_position_from_side(t, param[1]),
                      /* [] */0
                    ]
                  ]
                ]));
}

exports.playerColours = playerColours;
exports.calculate_from_radius = calculate_from_radius;
exports.calculate_from_dimensions = calculate_from_dimensions;
exports.calculate = calculate;
exports.calculate_x_position = calculate_x_position;
exports.calculate_y_position = calculate_y_position;
exports.get_x_position_from_side = get_x_position_from_side;
exports.get_y_position_from_side = get_y_position_from_side;
exports.get_position_from_side = get_position_from_side;
exports.centre = centre;
exports.get_positions = get_positions;
/* No side effect */
