// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Maze = require("./maze");
var Solution = require("./solution");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");

function make(maze, state, start_time, solution, time_remaining) {
  return /* record */[
          /* maze */maze,
          /* state */state,
          /* start_time */start_time,
          /* time_remaining */time_remaining,
          /* solution */solution
        ];
}

function string_of_state(param) {
  switch (param) {
    case /* WAITING */0 :
        return "WAITING";
    case /* PLAYING */1 :
        return "PLAYING";
    case /* SUCCESS */2 :
        return "SUCCESS";
    case /* FAILURE */3 :
        return "FAILURE";
    
  }
}

function state_of_string(param) {
  switch (param) {
    case "FAILURE" :
        return /* FAILURE */3;
    case "PLAYING" :
        return /* PLAYING */1;
    case "SUCCESS" :
        return /* SUCCESS */2;
    case "WAITING" :
        return /* WAITING */0;
    default:
      return Pervasives.failwith("invalid state: Game");
  }
}

function game_of_json(json) {
  var state = state_of_string(Json_decode.field("state", Json_decode.string, json));
  var start_time = Json_decode.field("start_time", Json_decode.date, json);
  var maze = Json_decode.field("maze", Maze.maze_of_json, json);
  var time_remaining = Json_decode.field("time_remaining", Json_decode.$$int, json);
  var solution = Json_decode.optional((function (param) {
          return Json_decode.field("solution", Solution.solution_of_json, param);
        }), json);
  return make(maze, state, start_time, solution, time_remaining);
}

function get_state(param) {
  return param[/* state */1];
}

function get_maze(param) {
  return param[/* maze */0];
}

function get_solution(param) {
  return param[/* solution */4];
}

function get_time_remaining(param) {
  return param[/* time_remaining */3];
}

exports.make = make;
exports.string_of_state = string_of_state;
exports.state_of_string = state_of_string;
exports.game_of_json = game_of_json;
exports.get_state = get_state;
exports.get_maze = get_maze;
exports.get_solution = get_solution;
exports.get_time_remaining = get_time_remaining;
/* No side effect */
