// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Lobby = require("../api/lobby");
var React = require("react");
var Player = require("../api/player");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var GameScreen = require("../game/GameScreen");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");

((require('./lobbyScreen.css')));

function str(prim) {
  return prim;
}

function get_nth_player(players, n) {
  try {
    return Player.get_displayable_name(Caml_array.caml_array_get(players, n));
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    if (exn[0] === Caml_builtin_exceptions.invalid_argument) {
      return null;
    } else {
      throw exn;
    }
  }
}

var component = ReasonReact.reducerComponentWithRetainedProps("Lobby");

function make(lobby, leaveLobby, changeBoardSize, changePrivacy, startGame, rotateHex, _children) {
  var boardSizeChanged = function ($$event) {
    return Curry._1(changeBoardSize, Caml_format.caml_int_of_string($$event.target.value));
  };
  var startGame$1 = function (param, self) {
    Curry._1(self[/* send */3], /* Show_game_board */0);
    return Curry._1(startGame, /* () */0);
  };
  var current_state = Lobby.get_state(lobby);
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */(function (self) {
              if (self[/* retainedProps */2][/* lobbyState */0] === /* IN_LOBBY */0 && current_state === /* IN_GAME */1) {
                return /* record */[/* showGameBoard */true];
              } else {
                return self[/* state */1];
              }
            }),
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var players = Lobby.get_players(lobby);
              var board_size = String(Lobby.get_board_size(lobby));
              var privacy = Lobby.get_privacy(lobby);
              var match = self[/* state */1][/* showGameBoard */0];
              var match$1 = Lobby.get_game(lobby);
              var exit = 0;
              var game;
              if (current_state) {
                if (match$1 !== undefined) {
                  game = match$1;
                  exit = 2;
                } else {
                  exit = 1;
                }
              } else if (match && match$1 !== undefined) {
                game = match$1;
                exit = 2;
              } else {
                exit = 1;
              }
              switch (exit) {
                case 1 :
                    return React.createElement("div", {
                                className: "lobby"
                              }, React.createElement("div", {
                                    className: "lobby-players"
                                  }, React.createElement("div", {
                                        className: "lobby-player-row"
                                      }, React.createElement("div", {
                                            className: "lobby-player p0-coloured"
                                          }, get_nth_player(players, 0)), React.createElement("div", {
                                            className: "lobby-player p1-coloured"
                                          }, get_nth_player(players, 1))), React.createElement("div", {
                                        className: "lobby-player-row"
                                      }, React.createElement("div", {
                                            className: "lobby-player p2-coloured"
                                          }, get_nth_player(players, 2)), React.createElement("div", {
                                            className: "lobby-player p3-coloured"
                                          }, get_nth_player(players, 3)))), React.createElement("div", {
                                    className: "lobby-sidebar"
                                  }, React.createElement("div", {
                                        className: "lobby-settings"
                                      }, React.createElement("div", {
                                            className: "lobby-name"
                                          }, "Lobby ID:", React.createElement("br", undefined), Lobby.get_id(lobby)), React.createElement("div", {
                                            className: "lobby-size"
                                          }, React.createElement("label", undefined, "Board size:"), React.createElement("br", undefined), React.createElement("input", {
                                                max: "8",
                                                min: 4,
                                                type: "range",
                                                value: board_size,
                                                onChange: boardSizeChanged
                                              }), React.createElement("br", undefined), board_size), React.createElement("div", {
                                            className: "lobby-privacy"
                                          }, React.createElement("label", undefined, "Privacy:"), React.createElement("br", undefined), React.createElement("input", {
                                                checked: privacy === /* PUBLIC */0,
                                                type: "radio",
                                                value: "Public",
                                                onChange: (function (param) {
                                                    return Curry._1(changePrivacy, /* PUBLIC */0);
                                                  })
                                              }), "Public", React.createElement("input", {
                                                checked: privacy === /* PRIVATE */1,
                                                type: "radio",
                                                value: "Private",
                                                onChange: (function (param) {
                                                    return Curry._1(changePrivacy, /* PRIVATE */1);
                                                  })
                                              }), "Private"), React.createElement("div", {
                                            className: "lobby-buttons"
                                          }, React.createElement("button", {
                                                className: "lobby-button_start",
                                                onClick: Curry._1(self[/* handle */0], startGame$1)
                                              }, "Start Game"), React.createElement("button", {
                                                className: "lobby-button_leave",
                                                onClick: leaveLobby
                                              }, "Leave Lobby")))));
                case 2 :
                    return ReasonReact.element(undefined, undefined, GameScreen.make(players, game, rotateHex, (function (param) {
                                      return Curry._1(self[/* send */3], /* Hide_game_board */1);
                                    }), /* array */[]));
                
              }
            }),
          /* initialState */(function (param) {
              return /* record */[/* showGameBoard */false];
            }),
          /* retainedProps : record */[/* lobbyState */current_state],
          /* reducer */(function (action, _state) {
              if (action) {
                return /* Update */Block.__(0, [/* record */[/* showGameBoard */false]]);
              } else {
                return /* Update */Block.__(0, [/* record */[/* showGameBoard */true]]);
              }
            }),
          /* jsElementWrapped */component[/* jsElementWrapped */13]
        ];
}

exports.str = str;
exports.get_nth_player = get_nth_player;
exports.component = component;
exports.make = make;
/*  Not a pure module */
